<template>
  <div id="divFaqWrapper" class="static-page-holder">
    <VueWrapperNarrow>
      <div v-html="content" class="faq-wrapper"></div>
    </VueWrapperNarrow>
  </div>
</template>

<script>
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import StaticContent from '@/services/Api/staticContents';
export default {
  name: 'FrequentlyAskedQuestions',
  components: { VueWrapperNarrow },
  data() {
    return { content: '' };
  },
  created() {
    StaticContent.getStaticContent('SikcaSorulanSorular')
      .then(res => {
        let {
          Data: { content },
        } = res.data;

        this.content = content;
      })
      .catch(err => console.error(err));
  },
};
</script>

<style scoped lang="scss">
.faq-wrapper {
  padding: palette-space-level('60') 0;
  line-height: 24px;
}
</style>
